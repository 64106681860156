import { Observable, shareReplay } from 'rxjs';

let confirmMessage: string | null;
let popStateConfirmMessage: string | null;
const beforeunloadHandler = (event: BeforeUnloadEvent) => {
  if (confirmMessage) {
    event.returnValue = confirmMessage;
    event.preventDefault();
    return confirmMessage;
  }
  return undefined;
};

const beforeunloadSource$ = new Observable<BeforeUnloadEvent>(observer => {
  const handler = (event: BeforeUnloadEvent) => {
    observer.next(event);
    return beforeunloadHandler(event);
  };
  window.addEventListener('beforeunload', handler);
  return () => window.removeEventListener('beforeunload', handler);
}).pipe(
  shareReplay()
);

const popstateSource$ = new Observable<PopStateEvent>(observer => {
  window.history.pushState(null, '', location.href);
  const handler = (event: PopStateEvent) => {
    if (confirm(popStateConfirmMessage)) {
      window.location.reload();
    } else {
      window.history.pushState(null, '', location.href);
    }
    observer.next(event);
  };
  window.addEventListener('popstate', handler);
  return () => window.removeEventListener('popstate', handler);
});

export function beforeunload$(): Observable<BeforeUnloadEvent> {
  confirmMessage = null;
  return beforeunloadSource$;
}

export function beforeUnloadWithConfirm$(message = '현재 나가면 내용이 초기화됩니다.'): Observable<BeforeUnloadEvent> {
  confirmMessage = message;
  return beforeunloadSource$;
}

export function popState$(message: string): Observable<PopStateEvent> {
  popStateConfirmMessage = message;
  return popstateSource$;
}
