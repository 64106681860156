// TODO 외부 주입 방식으로 변경 필요
const ngswBypass = 'ngsw-bypass';

export function addSWByPass(url: string): string {
  if (!/^http/.test(url)) {
    return url;
  }
  const parsedUrl = new URL(url);
  if (parsedUrl.searchParams.has(ngswBypass)) {
    return url;
  }
  parsedUrl.searchParams.append(ngswBypass, 'true');
  return parsedUrl.toString();
}

// TODO: 테스트 코드 작성 예정
export function getPath(url: string): string | null {
  if (!/^https?:\/?\/?\w+/.test(url)) {
    return null;
  }

  return new URL(url).pathname;
}
