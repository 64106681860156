import { Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';
import { forkJoin, from, interval, merge, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

const MINUTES_10 = 60 * 1000 * 10;

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private checkForUpdateSource = new Subject<void>();
  private intervalSource = interval(MINUTES_10);
  private hasUpdated = false;

  constructor(private swUpdate: SwUpdate) {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    console.log('[SW] UpdateService Init');
    forkJoin([
      this.versionUpdates$,
      this.checkForUpdate$,
      this.unrecoverable$,
    ]).subscribe();

    this.checkForUpdateSource.next();
  }

  private get versionUpdates$(): Observable<VersionEvent> {
    return this.swUpdate.versionUpdates.pipe(
      tap(evt => {
        console.log('[SW] update available');
        this.hasUpdated = evt.type === 'VERSION_READY';
      })
    );
  }

  private get checkForUpdate$(): Observable<boolean | void> {
    return merge(this.checkForUpdateSource, this.intervalSource).pipe(
      switchMap(() => from(this.swUpdate.checkForUpdate())),
      catchError(err => {
        console.error('[SW] Failed to check for updates:', err);
        return throwError(() => err);
      })
    );
  }

  private get unrecoverable$(): Observable<UnrecoverableStateEvent> {
    return this.swUpdate.unrecoverable.pipe(
      tap(evt => {
        console.log('[SW] unrecoverable', evt);
        this.reload();
      })
    );
  }

  public checkForUpdate(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    if (this.hasUpdated) {
      return this.reload();
    }
    this.checkForUpdateSource.next();
  }

  private reload(): void {
    document.location.reload();
  }
}
