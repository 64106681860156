import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { noop } from 'lodash-es';
import { defer, EMPTY, forkJoin, from, fromEvent, iif, Observable, of, retry, Subject } from 'rxjs';
import { concatMap, finalize, takeUntil, tap, toArray } from 'rxjs/operators';

type ExecuteCallback = () => void;

export interface ScriptLoaderParam {
  src: string;
  initExecute?: ExecuteCallback;
  execute?: ExecuteCallback;
}

@Injectable({
  providedIn: 'root'
})
export class ScriptLoader {
  private static scriptCache = new Set<string>();
  private static isLoadingScript = false;
  private renderer: Renderer2;
  private targetElement?: HTMLElement;
  private scripts: ScriptLoaderParam[] = [];
  private timeout = 10000;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private get element(): HTMLElement {
    return this.targetElement || document.body;
  }

  public setTimeout(timeout: number): ScriptLoader {
    this.timeout = timeout;
    return this;
  }

  public setTargetElement(element: HTMLElement): ScriptLoader {
    this.targetElement = element;
    return this;
  }

  public addScriptWithExecute(scriptLoaderParam: ScriptLoaderParam): ScriptLoader {
    this.scripts.push(scriptLoaderParam);
    return this;
  }

  public addScript(src: string): ScriptLoader {
    this.scripts.push({ src });
    return this;
  }

  public build({ useCache }: { useCache: boolean } = { useCache: false }): Observable<any> {
    return defer(() => {
      if (ScriptLoader.isLoadingScript) {
        return EMPTY;
      }
      ScriptLoader.isLoadingScript = true;
      return from(this.scripts);
    }).pipe(
      concatMap(script => this.executeScript(script, useCache)),
      toArray(),
      finalize(() => {
        this.scripts = [];
        ScriptLoader.isLoadingScript = false;
      })
    );
  }

  private executeScript({
                          src,
                          initExecute = noop,
                          execute = noop
                        }: ScriptLoaderParam, useCache: boolean): Observable<boolean> {
    return iif(() => useCache ? ScriptLoader.scriptCache.has(src) : false,
      of(true),
      this.loadScript(src).pipe(
        retry({ count: 1 }),
        tap(() => ScriptLoader.scriptCache.add(src)),
        tap(() => initExecute())
      )
    ).pipe(
      tap(() => execute())
    );
  }

  private loadScript(src: string): Observable<boolean> {
    return new Observable<any>(observer => {
      const script: HTMLScriptElement = this.renderer.createElement('script');
      const timerId = setTimeout(() => observer.error(new Error(`Script load timed out.`)), this.timeout);
      const destroyedSource = new Subject<void>();
      script.src = src;

      forkJoin([
        fromEvent(script, 'load').pipe(tap(() => {
          observer.next(true);
          observer.complete();
        })),
        fromEvent(script, 'error').pipe(tap(err => observer.error(err)))
      ]).pipe(
        takeUntil(destroyedSource),
        finalize(() => {
          clearTimeout(timerId);
          this.renderer.removeChild(this.element, script);
        })
      ).subscribe();

      this.renderer.appendChild(this.element, script);
      return () => destroyedSource.next();
    });
  }
}
